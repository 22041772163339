<!--模板中心-->
<template>
  <div id="appMain">
    <PanelHeader></PanelHeader>
    <main>
      <PanelLeft></PanelLeft>
      <div class="panel-mainContent" ref="mianRef">
        <div class="home-main">
          <div class="home-navcontent" ref="mianRef1">
            <UniversalNav class="search-nav"></UniversalNav>
          </div>
          <pubulius></pubulius>
        </div>
        <footer class="panel-footer">
          <public-footer></public-footer>
        </footer>
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
import PanelHeader from "@/layout/components/PanelHeader.vue";
import PanelLeft from "@/layout/components/PanelLeft.vue";
import PublicFooter from "@/src/components/PublicFooter/index";
import UniversalNav from "@/src/components/UniversalNav";
import pubulius from '@/src/components/pubuliu/index.vue'
import { getSeoList } from '@/src/api/apiconfig/main';

import { useLoginStore } from '@/src/store/modules/login'
const loginStore = useLoginStore();
const mianRef = ref<HTMLElement | null>(null)
const route = useRoute();
watch(() => route.query, async(newParam) => {
  const container = document.querySelector(".panel-mainContent")
  container.scrollTo(0, 0);
})

const gid = ref(useRoute().query.gid ?? 0); // 分类id
const id  = ref(useRoute().query.id ?? 0); // 行业id
const nid = ref(useRoute().query.nid ?? 0); // 场景id
const seo_title = ref('模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站')
const seo_key = ref('模板大全_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片')
const seo_des = ref('松鼠快图模板中心提供在线设计图片的多种图片设计模板，能够快速实现海报设计，图片设计，公众号图片设计等多种需求的在线设计图片功能。');
if(gid.value == 0 && id.value == 0 && nid.value == 0){

}else{
  const seo_res  = await getSeoList({gid:gid.value,id:id.value,nid:nid.value});
  const gid_name = seo_res.data.gid;
  const id_name = seo_res.data.id;
  const nid_name = seo_res.data.nid;
  seo_title.value = gid_name+'的'+nid_name+'适用于'+id_name+'行业的模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站';
  seo_key.value = nid_name+'模板_'+gid_name+'模板_'+id_name+'模板_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片';
  seo_des.value = '松鼠快图模板中心提供'+gid_name+'的'+nid_name+'适用于'+id_name+'行业的在线设计图片的多种图片设计模板，能够快速实现满足多种需求的在线图片设计功能。'
}

useHead({
  title:seo_title.value,
  meta:[
    {hid:'keywords',name:'keywords','content':seo_key.value},
    {hid:'description',name:'description','content':seo_des.value},
  ]
})

watch(() => [loginStore.template_g_name,loginStore.template_n_name,loginStore.template_h_name], async(newParams) => {
  if(newParams[0] == '全部' && newParams[1] == '全部' && newParams[2] == '全部'){
    useHead({
      title:'模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
      meta:[
        {hid:'keywords',name:'keywords','content':'模板大全_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片'},
        {hid:'description',name:'description','content':'松鼠快图模板中心提供在线设计图片的多种图片设计模板，能够快速实现海报设计，图片设计，公众号图片设计等多种需求的在线设计图片功能。'},
      ]
    })
  }else{
    useHead({
      title:newParams[0]+'的'+newParams[1]+'适用于'+newParams[2]+'行业的模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
      meta:[
        {hid:'keywords',name:'keywords','content':newParams[1]+'模板_'+newParams[0]+'模板_'+newParams[2]+'模板_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片'},
        {hid:'description',name:'description','content':'松鼠快图模板中心提供'+newParams[0]+'的'+newParams[1]+'适用于'+newParams[2]+'行业的在线设计图片的多种图片设计模板，能够快速实现满足多种需求的在线图片设计功能。'},
      ]
    })
  }
})

</script>
<style>
html,
body,
#app,
#__nuxt {
  height: 100%;
}
</style>
<style lang='less' scoped>
#appMain {
  display: flex;
  flex-direction: column;
  height: 100%;
  main {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;
    .panel-mainContent {
      flex: 1;
      overflow: auto;
    }
    .panel-footer {
      padding: 20px 45px;
    }
  }
}
</style>
<style lang='less' scoped>
.home-main {
  box-sizing: border-box;
  padding: 45px;
  padding-top: 0;
  .search-nav:first-child{
    margin-bottom: 10px;
  }
  .search-nav:last-child{
    margin-top: 10px;
  }
}
</style>
<style lang='scss' scoped>
.home-navcontent {
  padding: 25px 0px;
  background: $webRqBg;
  border-radius: 8px;
  margin-top: 0px;
}
</style>
