<!--模板中心-->
<template>
  <component :is="deviceComponent" />
</template>
<script setup lang="ts">
import TemplateCenterPc  from "@/src/components/templateCenter/templateCenterPc.vue";
import TemplateCenterWap from "@/src/components/wap/templateCenter/index.vue";
import { useDevice } from '@/src/utils/useDevice'
const { deviceComponent } = useDevice(TemplateCenterPc, TemplateCenterWap)
</script>
<style scoped>
body{

}
</style>
