<template>
  <div class="main-rq">
    <div class="search-rq">
      <el-input
          v-model="input"
          style="width: 100%"
          placeholder="搜索模板"
          :prefix-icon="Search"
          size="large"
          @keyup.enter="getSearch(input)"
      />
    </div>
    <el-scrollbar>
      <div class="scrollbar-flex-content">
        <p v-for="item in groupArray" :key="item" class="scrollbar-demo-item">
          <NuxtLink :class="{ selecteda: nid == item.id }" :title="item.title" :to="{name:'templateCenter',query:{gid:0,id:id,sid:sid,cid:cid,s:sort,nid:item.id,bid:bid,k:input,page:1}}">
          {{ item.title }}
          </NuxtLink>
        </p>
      </div>
    </el-scrollbar>
    <div class="imgBox">
      <watertemfallFlow
          :flowImgList="data"
          :total="total"
          :pagesize="pagesize"
          @page="getPage"
          :page="page"
      />
    </div>
  </div>
  <Footers></Footers>
</template>
<script setup lang="ts">
import Footers from '@/src/components/wap/footer/index.vue'
import {postWapTemplateGroup, getPosterList, getSeoList} from '@/src/api/apiconfig/main'
import { Search } from '@element-plus/icons-vue'
import watertemfallFlow from '@/src/components/wap/templateCenter/watertemfallFlow.vue'
import {ref} from "vue";
import { useLoginStore } from '@/src/store/modules/login'
const loginStore = useLoginStore();
const groupArray = ref(await postWapTemplateGroup());
const data = ref([]);
const pagesize = ref(40);//每页显示多少条记录
const total = ref(0);//总条数
// 接受url参数
const gid = ref(useRoute().query.gid ?? 0); // 分类id
const id  = ref(useRoute().query.id ?? 0); // 行业id
const sid = ref(useRoute().query.sid ?? 0); // 风格id
const cid = ref(useRoute().query.cid ?? 0); // 颜色id
const sort= ref(useRoute().query.s ?? 0); // 排序类型
const nid = ref(useRoute().query.nid ?? 0); // 场景id
const bid = ref(useRoute().query.bid ?? 0); // 版式id
const input = ref(useRoute().query.k ?? '');// 搜索内容
const page = ref(useRoute().query.page ?? 1); // 当前页面
// 监听路由变化
const route = useRoute();
watch(() => route.query, async(newParam,oldParam) => {
      if(oldParam == undefined){
        return;
      }
      gid.value = newParam.gid ?? 0;
      id.value = newParam.id ?? 0;
      sid.value = newParam.sid ?? 0;
      cid.value = newParam.cid ?? 0;
      sort.value = newParam.sort ?? 0;
      nid.value = newParam.nid ?? 0;
      bid.value = newParam.bid ?? 0;
      input.value=newParam.k ?? '';
      page.value = newParam.page ?? 1;
      await getData();
    },
    { immediate: true, deep: true }
);
const getSearch =((row)=>{
  if(row){
    input.value = row
  }
  navigateTo({ path: 'templateCenter',query:{
      gid:gid.value,
      id:id.value,
      sid:sid.value,
      cid:cid.value,
      nid:nid.value,
      bid:bid.value,
      s:sort.value,
      page:1,
      k:input.value
    }})
})
// 分页跳转
const getPage = (async (value)=>{
  page.value = value;
  navigateTo({ path: 'templateCenter',query:{
      gid:gid.value,
      id:id.value,
      sid:sid.value,
      cid:cid.value,
      nid:nid.value,
      bid:bid.value,
      s:sort.value,
      page:page.value,
      k:input.value
    }})
})

const getData = async () => {
  data.value = [];
  let response = await getPosterList({
    page: page.value,
    gid:gid.value,
    id:id.value,
    sid:sid.value,
    cid:cid.value,
    sort:sort.value,
    nid:nid.value,
    bid:bid.value,
    condition:input.value
  })
  data.value = response.data.list
  pagesize.value = Number(response.data.pagesize)
  total.value = Number(response.data.total)
  page.value = Number(response.data.page)
}
await getData();

const seo_title = ref('模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站')
const seo_key = ref('模板大全_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片')
const seo_des = ref('松鼠快图模板中心提供在线设计图片的多种图片设计模板，能够快速实现海报设计，图片设计，公众号图片设计等多种需求的在线设计图片功能。');
if(gid.value == 0 && id.value == 0 && nid.value == 0){

}else{
  const seo_res  = await getSeoList({gid:gid.value,id:id.value,nid:nid.value});
  const gid_name = seo_res.data.gid;
  const id_name = seo_res.data.id;
  const nid_name = seo_res.data.nid;
  seo_title.value = gid_name+'的'+nid_name+'适用于'+id_name+'行业的模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站';
  seo_key.value = nid_name+'模板_'+gid_name+'模板_'+id_name+'模板_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片';
  seo_des.value = '松鼠快图模板中心提供'+gid_name+'的'+nid_name+'适用于'+id_name+'行业的在线设计图片的多种图片设计模板，能够快速实现满足多种需求的在线图片设计功能。'
}

useHead({
  title:seo_title.value,
  meta:[
    {hid:'keywords',name:'keywords','content':seo_key.value},
    {hid:'description',name:'description','content':seo_des.value},
  ]
})

watch(() => [loginStore.template_g_name,loginStore.template_n_name,loginStore.template_h_name], async(newParams) => {
  if(newParams[0] == '全部' && newParams[1] == '全部' && newParams[2] == '全部'){
    useHead({
      title:'模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
      meta:[
        {hid:'keywords',name:'keywords','content':'模板大全_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片'},
        {hid:'description',name:'description','content':'松鼠快图模板中心提供在线设计图片的多种图片设计模板，能够快速实现海报设计，图片设计，公众号图片设计等多种需求的在线设计图片功能。'},
      ]
    })
  }else{
    useHead({
      title:newParams[0]+'的'+newParams[1]+'适用于'+newParams[2]+'行业的模板中心_松鼠快图_松鼠快图官网_在线图片编辑_在线作图网站',
      meta:[
        {hid:'keywords',name:'keywords','content':newParams[1]+'模板_'+newParams[0]+'模板_'+newParams[2]+'模板_松鼠快图,松鼠快图官网,在线图片编辑,在线作图网站,在线编辑图片,在线设计图片'},
        {hid:'description',name:'description','content':'松鼠快图模板中心提供'+newParams[0]+'的'+newParams[1]+'适用于'+newParams[2]+'行业的在线设计图片的多种图片设计模板，能够快速实现满足多种需求的在线图片设计功能。'},
      ]
    })
  }
})

</script>
<style scoped>
.main-rq{
  padding:10px 5px;
}
.search-rq{
  display: flex;
  justify-content: center;
}
.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  color: #333;
  position: relative;
}
.selecteda::after{
  content: ' ';
  display: block;
  width: 20px;
  height: 3px;
  background-color: aqua;
  position: absolute;
  left: 50%; /* 将左边距设置为50% */
  bottom: 0;
  border-radius: 2px;
  transform: translateX(-50%); /* 向左移动自身宽度的一半以居中 */
}
:deep(.el-scrollbar__bar){
  display: none;
}
</style>